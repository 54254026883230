<template>
  <div class="test">
    <ProgressBar :totalSteps="sectionLength * 4 + 2" :stepNumber="globalQuestionIndex" />
    <div class="tips">
      <h3>💡{{ $t('hint') }}</h3>
      <ul>
        <li>{{ tips[tipIndex] }}</li>
      </ul>
    </div>
    <transition name="fade">
      <p v-if="loading">{{ $t('loading') }}</p>
      <div v-else-if="questionnaireSection && show" class="questionLine">
        <div class="choice" @click="nextQuestionLine(randomizedLine[0])">
          <p class="choice__text noselect">{{ randomizedLine[0].text }}</p>
        </div>
        <span>{{ $t('or') }}</span>
        <div class="choice" @click="nextQuestionLine(randomizedLine[1])">
          <p class="choice__text noselect">{{ randomizedLine[1].text }}</p>
        </div>
      </div>
    </transition>
    <div v-if="personalityType">{{ personalityType.title }}</div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ProgressBar from '../components/ProgressBar.vue';

export default {
  name: 'Questionnaire',
  components: {
    ProgressBar,
  },
  created() {
    this.loading = true;
    this.$apollo.mutate({
      mutation: gql`mutation startQuestionnaire($email: String!) {
        startQuestionnaire(email: $email) {
          questionnaireSubmissionId
          firstQuestionnaireSection {
            id
            firstTrait
            secondTrait
            lines {
              id
              weight
              firstStatement {
                id
                relatedTrait
                text
              }
              secondStatement {
                id
                relatedTrait
                text
              }
            }
          }
        }
      }`,
      variables: {
        email: this.$store.getters.userEmail,
      },
    }).then((response) => {
      this.loading = false;
      this.questionnaireSection = response.data.startQuestionnaire.firstQuestionnaireSection;
      this.submissionId = response.data.startQuestionnaire.questionnaireSubmissionId;
      this.sectionLength = response.data.startQuestionnaire.firstQuestionnaireSection.lines.length;
    });
  },
  data() {
    return {
      show: true,
      currentQuestionIndex: 0,
      globalQuestionIndex: 0,
      sectionResults: {},
      questionnaireSection: null,
      submissionId: null,
      sectionAnswers: [],
      personalityType: null,
      sectionLength: null,
      error: null,
      tips: [
        this.$t('Questionnaire.Hints.1'),
        this.$t('Questionnaire.Hints.2'),
        this.$t('Questionnaire.Hints.3'),
        this.$t('Questionnaire.Hints.4'),
        this.$t('Questionnaire.Hints.5'),
      ],
      tipIndex: 0,
      loading: false,
    };
  },
  methods: {
    nextQuestionLine(statement) {
      this.show = false;
      const answers = this.sectionAnswers;
      const answer = { questionnaireLineId: this.currentLine.id, chosenStatementId: statement.id };
      this.sectionAnswers = [...answers, answer];
      const currentScore = this.sectionResults[statement.relatedTrait] || 0;
      this.sectionResults[statement.relatedTrait] = currentScore + this.lineWeight;
      if (this.lastSectionLine) {
        this.getNextSection();
      }
      this.currentQuestionIndex += 1;
      this.globalQuestionIndex += 1;
      setTimeout(() => {
        this.show = true;
        this.tipIndex = Math.floor((Math.random() * this.tips.length));
      }, 500);
    },
    getNextSection() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: gql`mutation submitQuestionnaireSection(
          $resultTrait: CharacterTrait!,
          $sectionId: ID!,
          $questionnaireSubmissionId: UUID!,
          $sectionAnswers: [QuestionnaireSectionAnswer],
        ) {
          submitQuestionnaireSection(
            resultTrait: $resultTrait,
            sectionId: $sectionId,
            questionnaireSubmissionId: $questionnaireSubmissionId,
            sectionAnswers: $sectionAnswers,
          ) {
            questionnaireFinished
            nextSection {
              id
              firstTrait
              secondTrait
              lines {
                id
                weight
                firstStatement {
                  id
                  relatedTrait
                  text
                }
                secondStatement {
                  id
                  relatedTrait
                  text
                }
              }
            }
            personalityType {
              id
              title
              shortTitle
              description
              mostCompatibleTypes {
                intertypeRelation {
                  title
                }
                personalityType {
                  id
                  title
                  shortTitle
                  description
                }
              }
            }
          }
        }`,
        variables: {
          resultTrait: this.sectionWinner,
          sectionId: this.questionnaireSection.id,
          sectionAnswers: this.sectionAnswers,
          questionnaireSubmissionId: this.submissionId,
        },
      }).then((response) => {
        this.loading = false;
        const result = response.data.submitQuestionnaireSection;
        this.questionnaireSection = result.nextSection;
        if (result.questionnaireFinished) {
          this.$router.push({
            name: 'PersonalityType',
            params: {
              id: result.personalityType.shortTitle.toLowerCase(),
              pt: JSON.stringify(result.personalityType),
            },
          });
        }
        this.resetValues();
      }).catch((error) => {
        this.error = error;
      });
    },
    resetValues() {
      this.sectionResults = {};
      this.currentQuestionIndex = 0;
      this.sectionAnswers = [];
      this.error = null;
    },
  },
  computed: {
    randomizedLine() {
      const randomInt = Math.floor((Math.random() * 2));
      const line = this.currentLine;
      if (line && randomInt) {
        return [line.firstStatement, line.secondStatement];
      }
      return [line.secondStatement, line.firstStatement];
    },
    currentLine() {
      return this.questionnaireSection.lines[this.currentQuestionIndex];
    },
    lineWeight() {
      return this.questionnaireSection.lines[this.currentQuestionIndex].weight;
    },
    lastSectionLine() {
      return this.currentQuestionIndex + 1 === this.questionnaireSection.lines.length;
    },
    sectionWinner() {
      return Object.keys(this.sectionResults)
        .reduce((a, b) => (this.sectionResults[a] > this.sectionResults[b] ? a : b));
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints' as *;
@use '@/styles/variables' as *;

.test {
  height: 100vh;
  background-color: $myTypeLightGray;

  .tips {
    padding-top: 2em;
    line-height: 1.5em;
  }

  ul {
    list-style-type: none;
    margin: 1em;
    margin-bottom: 2em;
    padding: 0;
  }
}

.questionLine {
  display: grid;
  grid-template-rows: 16em -1px 16em;
  align-items: center;
  justify-content: center;

  @include breakpoint(medium) {
    grid-template-rows: 16em 5px 16em;
  }

  @include breakpoint(large) {
    grid-template-columns: 35em 50px 35em;
    column-gap: 10px;
  }

  .choice {
    display: flex;
    padding: 0.5em 1.5em;
    margin: 1em;
    min-height: 5em;
    background-color: white;
    font-size: 1.2em;
    line-height: 1.5em;
    border: 1px solid transparent;
    box-shadow: $shadow-medium;

    &:active {
      transform: scale(0.95);
      box-shadow: $shadow-small;
    }

    @include breakpoint(large) {
      &:hover {
        cursor: pointer;
        border: 1px solid $myTypeRed;
      }
    }

    @include breakpoint(medium) {
      padding: 1em 2em;
      font-size: 1.5em;
      line-height: 1.3em;
    }

    &__text {
      margin: auto;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
