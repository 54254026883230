<template>
  <div class='grid-box' @click="reroute">
    <div :style="backgroundImage()" class="image" />
    <h3 class='title'>{{ getTitle(personalityType.title) }}</h3>
    <p class='bold-gray'>{{ personalityType.shortTitle }}</p>
  </div>
</template>

<script>
export default {
  name: 'TypeCard',
  props: {
    personalityType: {
      type: Object,
      default: null,
    },
  },
  methods: {
    reroute() {
      this.$router.push({
        name: 'PersonalityType',
        params: {
          id: this.personalityType.shortTitle.toLowerCase(),
          pt: JSON.stringify(this.personalityType),
        },
      });
    },
    backgroundImage() {
      return `background-image: url('/personality_types/${this.personalityType.shortTitle.toLowerCase()}.svg')`;
    },
    getTitle(string) {
      // remove 'The'
      if (this.$i18n.locale === 'ru') return string;
      const stripped = string.substring(4);
      return stripped.substring(0, stripped.length - 7);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints' as *;
@use '@/styles/variables' as *;

.grid-box {
  text-align: center;
  text-decoration: none;
  color: $myTypeDarkGray;
  cursor: pointer;
}

.image {
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  transition: transform .3s;
  margin: auto;

  &:hover {
    -ms-transform: scale(1.4); /* IE 9 */
    -webkit-transform: scale(1.4); /* Safari 3-8 */
    transform: scale(1.4);
  }

  @include breakpoint(large) {
    height: 200px;
    width: 10em;
  }

  @include breakpoint(xlarge) {
    height: 150px;
    width: 18em;
  }

  @include breakpoint(xxlarge) {
    height: 150px;
    width: 20em;
  }
}

.title {
  margin-bottom: 0.5em;
}
</style>
