<template>
  <div v-if="loading" class="center center__content">{{ $t('loading') }}</div>
  <div v-else class="center background-top-left" :style="backgroundImage" :key="personalityType.id">
    <div class="center__content opacity">
      <h2>{{ personalityType.title }}</h2>
      <p class="description">{{ personalityType.description }}</p>
      <div class="share margin-bottom">
        <ShareNetwork
          network="facebook"
          :url='"https://www.meetch.app/en/personality-types/" + personalityType.shortTitle.toLowerCase()'
          :title="personalityType.title"
          :description="personalityType.description.substring(0, 512) + '...'"
          hashtags="personalitytype, personalitytest, meetchapp"
        >
          <div class="social-icon social-icon__fb" />
        </ShareNetwork>
        <ShareNetwork
          network="twitter"
          :url='"https://www.meetch.app/en/personality-types/" + personalityType.shortTitle.toLowerCase()'
          :title="personalityType.title"
          twitter-user="meetch46280962"
          hashtags="personalitytype,personalitytest,meetchapp"
        >
          <div class="social-icon social-icon__twitter" />
        </ShareNetwork>
        <ShareNetwork
          network="reddit"
          :url='"https://www.meetch.app/en/personality-types/" + personalityType.shortTitle.toLowerCase()'
          :title="personalityType.title"
        >
          <div class="social-icon social-icon__reddit" />
        </ShareNetwork>
        <ShareNetwork
          network="linkedin"
          :title="personalityType.title"
          :description="personalityType.description.substring(0, 512) + '...'"
          :url='"https://www.meetch.app/en/personality-types/" + personalityType.shortTitle.toLowerCase()'
        >
          <div class="social-icon social-icon__linkedin" />
        </ShareNetwork>
        <ShareNetwork
          network="vk"
          :title="personalityType.title"
          :description="personalityType.description.substring(0, 512) + '...'"
          :url='"https://www.meetch.app/en/personality-types/" + personalityType.shortTitle.toLowerCase()'
        >
          <div class="social-icon social-icon__vk" />
        </ShareNetwork>
      </div>
    </div>
    <h2 class="subheader">{{ $t('PersonalityType.Recommended.Title') }}</h2>
    <p>
      {{ $t('PersonalityType.Recommended.For') }} {{ personalityType.title }}
      {{ $t('PersonalityType.Recommended.For.P2') }}</p>
    <div class="grid cols-3 recommendations margin-bottom margin-top">
      <div v-for="obj in recommended" :key="obj.id" @click="reroute(obj.personalityType)">
        <TypeCard :personalityType="obj.personalityType" />
        <p class="bold-gray">{{ obj.intertypeRelation.title }}</p>
      </div>
    </div>
    <h2 class="subheader margin-top">{{ $t('PersonalityType.Recommended.Other.Title') }}</h2>
    <p>{{ $t('PersonalityType.Recommended.Other.Description') }}</p>
    <MyTypeButton
      :text='$t("PersonalityType.Recommended.Other.Calculator")'
      class="margin-top"
      :onClick='redirectToCalculator'
    />
  </div>
</template>

<script>
import { useQuery, useResult } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { GET_PERSONALITY_TYPES } from '../apollo/queries';
import TypeCard from '../components/TypeCard.vue';
import MyTypeButton from '../components/MyTypeButton.vue';

export default {
  components: { TypeCard, MyTypeButton },
  name: 'PersonlaityType',
  setup() {
    const route = useRoute();
    const store = useStore();

    if (!store.getters.personalityTypes.length) {
      const { result, loading, error } = useQuery(GET_PERSONALITY_TYPES);
      const personalityTypes = useResult(result);

      return {
        loading,
        error,
        personalityType: computed(
          () => personalityTypes.value.find(
            (t) => t.shortTitle.toLowerCase() === route.params.id,
          ) || null,
        ),
      };
    }

    return {
      loading: false,
      personalityType: computed(
        () => store.getters.personalityTypes.find(
          (t) => t.shortTitle.toLowerCase() === route.params.id,
        ) || null,
      ),
    };
  },
  computed: {
    backgroundImage() {
      return `background-image: url('/personality_types/${this.personalityType.shortTitle.toLowerCase()}.svg')`;
    },
    recommended() {
      return this.personalityType.mostCompatibleTypes;
    },
  },
  methods: {
    redirectToCalculator() {
      this.$router.push({
        name: 'Calculator',
      });
    },
    reroute(personality) {
      this.$router.push({
        name: 'PersonalityType',
        params: {
          id: personality.shortTitle.toLowerCase(),
          pt: JSON.stringify(personality),
        },
      });
      window.scrollTo({ top: 0 });
      this.personalityType = this.$store.getters.personalityTypes.find(
        (t) => t.id === personality.id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/index" as *;
@use "@/styles/breakpoints" as *;
@use "@/styles/variables" as *;

.background-top-left {
  background-position: 0% -2%;
  background-size: 120%;
  background-repeat: no-repeat;
  padding: 10em 1em;

  @include breakpoint(medium) {
    background-size: 60%;
    background-position: 0% -2%;
  }

  @include breakpoint(xlarge) {
    background-size: 40%;
    background-position: 10% -2%;
  }
}

.opacity {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2em;

  @include breakpoint(medium) {
    width: 80%;
    padding: 2em;
  }

  @include breakpoint(xlarge) {
    width: 50%;
    padding: 2em;
  }
}

.description {
  margin-bottom: 2em;
  white-space: break-spaces;
  font-size: 18px;
  line-height: 1.5em;
  text-align: left;
  color: $myTypeDarkGray;
  opacity: 0.9;
}

.recommendations {
  margin: 2em auto;
  grid-gap: 1.6em;

  @include breakpoint(large) {
    width: 60%;
  }

  .image {
    height: 100px;
  }
}

.share {
  display: flex;
  justify-content: center;
  gap: 2em;
}

.social-icon {
  height: 30px;
  width: 30px;
  margin: auto;
  background-repeat: no-repeat;

  &__twitter {
    background-image: url('../assets/logo-twitter.svg');
  }
  &__fb {
    background-image: url('../assets/logo-facebook.svg');
  }
  &__linkedin {
    background-image: url('../assets/logo-linkedin.svg');
  }
  &__reddit {
    background-image: url('../assets/logo-reddit.svg');
  }
  &__vk {
    background-image: url('../assets/logo-vk.svg');
  }
}
</style>
