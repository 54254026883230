<template>
  <div class="center background-top-left" :style="backgroundImage">
    <div class="center__content opacity">
      <h2>{{ data.relationshipType.title }}</h2>
      <p class="bold-gray">{{ subtitle }}</p>
      <p class="description">{{ data.relationshipType.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RelationshipType',
  props: {
    relationshipType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: {
        relationshipType: {},
        relationshipTypeKey: 0,
      },
    };
  },
  created() {
    this.data.relationshipType = JSON.parse(this.relationshipType);
  },
  computed: {
    backgroundImage() {
      return `background-image: url('/relations/${this.data.relationshipType.relationType.toLowerCase()}.svg')`;
    },
    subtitle() {
      switch (true) {
        case (this.data.relationshipType.rating === 1):
          return this.$t('Compatibility.Highest');
        case this.data.relationshipType.group === 1:
          return this.$t('Compatibility.High');
        case this.data.relationshipType.group === 2:
          return this.$t('Compatibility.Average');
        case this.data.relationshipType.group === 3:
          return this.$t('Compatibility.Below.Average');
        default:
          return this.$t('Compatibility.Low');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/index" as *;
@use "@/styles/breakpoints" as *;
@use "@/styles/variables" as *;

.background-top-left {
  background-repeat: no-repeat;
  padding: 10em 1em;
  background-position: top;
  background-size: 55%;

  @include breakpoint(medium) {
    background-size: 60%;
    background-position: 0% -2%;
  }

  @include breakpoint(xlarge) {
    background-size: 35%;
    background-position: 10% -2%;
  }
}

.opacity {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2em;

  @include breakpoint(medium) {
    width: 80%;
    padding: 2em;
  }

  @include breakpoint(xlarge) {
    width: 50%;
    padding: 2em;
  }
}

.description {
  white-space: break-spaces;
  font-size: 18px;
  line-height: 1.5em;
  text-align: left;
  color: $myTypeDarkGray;
  opacity: 0.9;
}
</style>
