<template>
  <div class="relationship" @click="reroute" @mouseenter="playAnimation" @focus="playAnimation">
    <lottie-animation
      :ref="relationshipType.relationType.toLowerCase()"
      :animationData="
        require(`@/assets/animations/${relationshipType.relationType.toLowerCase()}.json`)
      "
      :speed="0.7"
      :autoPlay="false"
      class="relationship__image"
    />
    <h3 class='title'>{{ relationshipType.title }}</h3>
    <p class='bold-gray relationship__description'>
      {{ relationshipType.description.split('.', 1)[0] }}
    </p>
  </div>
</template>

<script>
const isMobile = () => window.innerWidth <= 820;

export default {
  name: 'RelationshipTypeCard',
  props: {
    relationshipType: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  beforeUnmount() {
    if (isMobile()) {
      this.observer.disconnect();
    }
  },
  created() {
    if (isMobile()) {
      this.observer = new IntersectionObserver(
        this.onElementObserved,
        {
          root: this.$el,
          threshold: 1.0,
        },
      );
    }
  },
  mounted() {
    if (isMobile()) {
      this.observer.observe(this.$el);
    }
  },
  methods: {
    reroute() {
      this.$router.push({
        name: 'RelationshipType',
        params: {
          id: this.relationshipType.relationType,
          relationshipType: JSON.stringify(this.relationshipType),
        },
      });
    },
    getSubtitle(string) {
      const stripped = string.substring(4);
      return stripped.substr(stripped.length - 5).substring(0, 4);
    },
    playAnimation() {
      this.$refs[this.relationshipType.relationType.toLowerCase()].stop();
      this.$refs[this.relationshipType.relationType.toLowerCase()].play();
    },
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        setTimeout(() => {
          this.playAnimation();
        }, 500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/styles/breakpoints' as *;
@use '@/styles/variables' as *;

.relationship {
  flex: 1;
  margin-bottom: 4em;
  cursor: pointer;
  transition: transform ease 300ms;

  &:hover {
    transform: translate(0, -1em);
  }

  @include breakpoint(xlarge) {
    margin-bottom: 0;
  }

  &__image {
    height: 12em;
  }

  &__description {
    max-width: 310px;
    margin: auto;
  }
}

</style>
